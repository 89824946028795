<template>
    <a class="inline-block" :class="[($route.url == assembledHref ? $router.linkActiveClass : '')]" :href="assembledHref">
      <slot/>
    </a>
</template>

<script>
export default {
  name: "Link",
  props: {
    href: {
      type: [String, Object],
    }
  },
  computed: {
    assembledHref() {
      return this.$router.assembleUrl(this.href)
    }
  },
}
</script>

<style scoped>
a, a:active, a:visited, a[tabindex]:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}
</style>
